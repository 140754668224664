import styles from "pages/login/css/LoginOrRegisterPage.module.css";
import PageBody from "components/containers/PageBody";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import LoginOrRegisterEmailForm from "components/login/LoginOrRegisterEmailForm";
import LoginOrRegisterNonceForm from "components/login/LoginOrRegisterNonceForm";
import { useState } from "react";
import LoginOrRegister from "types/LoginOrRegister";
import useQueryParams from "hooks/useQueryParams";
import { useLocation } from "react-router-dom";
import RegisterCreateAccount from "components/login/RegisterCreateAccount";
import RedirectIfLoggedInContainer from "components/auth/RedirectIfLoggedInContainer";
import GoogleLoginButton from "components/auth/GoogleLoginButton";
import Body1Medium from "components/text/Body1Medium";
import ColorClass from "types/enums/ColorClass";
import UrlParam from "harken-shared/dist/types/enums/UrlParam";

type Props = {
  loginOrRegister: LoginOrRegister;
};

function LoginOrRegisterContent({ loginOrRegister }: Props) {
  const [showNonceForm, setShowNonceForm] = useState<boolean>(false);

  const form = showNonceForm ? (
    <LoginOrRegisterNonceForm loginOrRegister={loginOrRegister} />
  ) : (
    <LoginOrRegisterEmailForm
      loginOrRegister={loginOrRegister}
      onSuccess={() => setShowNonceForm(true)}
    />
  );
  return (
    <div className={styles.form}>
      {form}
      <div className={styles.separator}>
        <div className={styles.separatorLine} />
        <Body1Medium
          className={styles.separatorText}
          colorClass={ColorClass.Secondary}
          textTransform="uppercase"
        >
          Or
        </Body1Medium>
        <div className={styles.separatorLine} />
      </div>
      <div className={styles.googleLoginButtonContainer}>
        <GoogleLoginButton />
      </div>
    </div>
  );
}

function Container({
  children,
  loginOrRegister,
}: {
  children: any;
  loginOrRegister: LoginOrRegister;
}) {
  const headerText = loginOrRegister === "login" ? "Sign In" : "Get Started";

  return (
    <PageWithHeaderAndFooter>
      <PageBody header={headerText}>{children}</PageBody>
    </PageWithHeaderAndFooter>
  );
}

export default function LoginOrRegisterPage({ loginOrRegister }: Props) {
  const { pathname } = useLocation();
  const params = useQueryParams();

  const nonce = params.get(UrlParam.Nonce);
  const googleIdToken = params.get(UrlParam.GoogleIdToken);
  if ((nonce != null || googleIdToken != null) && pathname === "/register") {
    return (
      <RedirectIfLoggedInContainer>
        <Container loginOrRegister={loginOrRegister}>
          <div className={styles.form}>
            <RegisterCreateAccount
              createAccountNonce={nonce}
              googleIdToken={googleIdToken}
            />
          </div>
        </Container>
      </RedirectIfLoggedInContainer>
    );
  }

  return (
    <RedirectIfLoggedInContainer>
      <Container loginOrRegister={loginOrRegister}>
        <LoginOrRegisterContent loginOrRegister={loginOrRegister} />
      </Container>
    </RedirectIfLoggedInContainer>
  );
}
