/**
 * @generated SignedSource<<e19c349a20ad04634c90dd140a11593a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TestPageQuery$variables = {};
export type TestPageQuery$data = {
  readonly test: string | null;
};
export type TestPageQuery = {
  response: TestPageQuery$data;
  variables: TestPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "test",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TestPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f1956bf3a1c7d85b4c3a1e2b72e680df",
    "id": null,
    "metadata": {},
    "name": "TestPageQuery",
    "operationKind": "query",
    "text": "query TestPageQuery {\n  test\n}\n"
  }
};
})();

(node as any).hash = "d17d9b105d0904982251158355dd4760";

export default node;
