import { message as antdMessage } from "antd";
import styles from "components/toast/css/message.module.css";

interface IMessageArgs {
  content: string;
  duration?: number;
  type: "info" | "success" | "error" | "warning";
}

// eslint-disable-next-line import/prefer-default-export
export default function message({ content, duration = 3, type }: IMessageArgs) {
  antdMessage[type]({
    className: styles.message,
    content,
    duration,
    type,
  });
}
