import { Tooltip } from "antd";
import FlexBox from "components/layout/FlexBox";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";

type Props = {
  children: JSX.Element;
  tooltipContent: JSX.Element | string;
};

export default function WrapWithTooltip({ children, tooltipContent }: Props) {
  const tooltipContentElem =
    typeof tooltipContent === "string" ? (
      <Body1 colorClass={ColorClass.Primary}>{tooltipContent}</Body1>
    ) : (
      tooltipContent
    );

  return (
    <Tooltip color={ColorValue.WebsiteBackground} title={tooltipContentElem}>
      {/* The extra div is needed due to a bug in the component.
      See https://github.com/ant-design/ant-design/issues/15909 */}
      {children.type === "div" ? (
        children
      ) : (
        <FlexBox alignItems="center">{children}</FlexBox>
      )}
    </Tooltip>
  );
}
