import styles from "components/containers/css/HideIfEmpty.module.css";
import { forwardRef } from "react";
import joinClasses from "utils/joinClasses";

type Props = {
  children: any;
  className?: string;
};

/**
 * See https://stackoverflow.com/a/70806881 for an in-depth explanation of when this component comes in handy.
 */
const HideIfEmpty = forwardRef<HTMLDivElement, Props>(
  ({ children, className }, ref) => (
    <div className={joinClasses(styles.container, className)} ref={ref}>
      {children}
    </div>
  )
);

export default HideIfEmpty;
