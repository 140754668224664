/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorVariableLightModeName {
  ArtistPillShadow = "--color-artistPillShadow-lightMode",
  BackgroundOverlay = "--color-backgroundOverlay-lightMode",
  BrightPurple = "--color-brightPurple-lightMode",
  CardBackground = "--color-cardBackground-lightMode",
  ClickableAreaBackground = "--color-clickableAreaBackground-lightMode",
  DarkCerulean = "--color-darkCerulean-lightMode",
  DarkPurple = "--color-darkPurple-lightMode",
  Dimmer = "--color-dimmer-lightMode",
  Error = "--color-error-lightMode",
  FloatingElement = "--color-floatingElement-lightMode",
  Footer = "--color-footer-lightMode",
  Ghost = "--color-ghost-lightMode",
  Green = "--color-green-lightMode",
  LightBlue = "--color-lightBlue-lightMode",
  LightPink = "--color-lightPink-lightMode",
  LightPurple = "--color-lightPurple-lightMode",
  LightPurpleHover = "--color-lightPurpleHover-lightMode",
  LinkWater = "--color-linkWater-lightMode",
  MenuPopup = "--color-menuPopup-lightMode",
  Navy = "--color-navy-lightMode",
  NavyHover = "--color-navyHover-lightMode",
  OverlayButton = "--color-overlayButton-lightMode",
  Pink = "--color-pink-lightMode",
  Primary = "--color-primary-lightMode",
  ProgressBar = "--color-progressBar-lightMode",
  Purple = "--color-purple-lightMode",
  PurpleGradient = "--color-purpleGradient-lightMode",
  PurpleGradientHover = "--color-purpleGradientHover-lightMode",
  Red = "--color-red-lightMode",
  RedHover = "--color-redHover-lightMode",
  Secondary = "--color-secondary-lightMode",
  Shader = "--color-shader-lightMode",
  SkeletonBase = "--color-skeletonBase-lightMode",
  SkeletonHighlight = "--color-skeletonHighlight-lightMode",
  SpotlightOverlay = "--color-spotlightOverlay-lightMode",
  Tertiary = "--color-tertiary-lightMode",
  WebsiteBackground = "--color-websiteBackground-lightMode",
  White = "--color-white-lightMode",
  Yellow = "--color-yellow-lightMode",
}

export default ColorVariableLightModeName;
