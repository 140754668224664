/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorVariableDarkModeName {
  ArtistPillShadow = "--color-artistPillShadow-darkMode",
  BackgroundOverlay = "--color-backgroundOverlay-darkMode",
  BrightPurple = "--color-brightPurple-darkMode",
  CardBackground = "--color-cardBackground-darkMode",
  ClickableAreaBackground = "--color-clickableAreaBackground-darkMode",
  DarkCerulean = "--color-darkCerulean-darkMode",
  DarkPurple = "--color-darkPurple-darkMode",
  Dimmer = "--color-dimmer-darkMode",
  Error = "--color-error-darkMode",
  FloatingElement = "--color-floatingElement-darkMode",
  Footer = "--color-footer-darkMode",
  Ghost = "--color-ghost-darkMode",
  Green = "--color-green-darkMode",
  LightBlue = "--color-lightBlue-darkMode",
  LightPink = "--color-lightPink-darkMode",
  LightPurple = "--color-lightPurple-darkMode",
  LightPurpleHover = "--color-lightPurpleHover-darkMode",
  LinkWater = "--color-linkWater-darkMode",
  MenuPopup = "--color-menuPopup-darkMode",
  Navy = "--color-navy-darkMode",
  NavyHover = "--color-navyHover-darkMode",
  OverlayButton = "--color-overlayButton-darkMode",
  Pink = "--color-pink-darkMode",
  Primary = "--color-primary-darkMode",
  ProgressBar = "--color-progressBar-darkMode",
  Purple = "--color-purple-darkMode",
  PurpleGradient = "--color-purpleGradient-darkMode",
  PurpleGradientHover = "--color-purpleGradientHover-darkMode",
  Red = "--color-red-darkMode",
  RedHover = "--color-redHover-darkMode",
  Secondary = "--color-secondary-darkMode",
  Shader = "--color-shader-darkMode",
  SkeletonBase = "--color-skeletonBase-darkMode",
  SkeletonHighlight = "--color-skeletonHighlight-darkMode",
  SpotlightOverlay = "--color-spotlightOverlay-darkMode",
  Tertiary = "--color-tertiary-darkMode",
  WebsiteBackground = "--color-websiteBackground-darkMode",
  White = "--color-white-darkMode",
  Yellow = "--color-yellow-darkMode",
}

export default ColorVariableDarkModeName;
