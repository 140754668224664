import ElementId from "types/enums/ElementId";
import joinClasses from "utils/joinClasses";
import styles from "components/loading/css/BackgroundOverlay.module.css";
import ColorValue from "types/enums/ColorValue";
import useColorModeContext from "hooks/contexts/useColorModeContext";

type Props = {
  children?: JSX.Element | Array<JSX.Element>;
  className?: string;
};

function getStylesForTheme(isLightMode: boolean) {
  const defaultStyles = { backgroundColor: ColorValue.BackgroundOverlay };

  return isLightMode
    ? {
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
      }
    : defaultStyles;
}

export default function BackgroundOverlay({
  children,
  className,
}: Props): JSX.Element {
  const { isLightMode } = useColorModeContext();

  return (
    <div
      className={joinClasses(styles.backgroundOverlay, className)}
      id={ElementId.BackgroundOverlay}
      style={getStylesForTheme(isLightMode)}
    >
      {children}
    </div>
  );
}
