import Environment from "types/enums/Environment";
import getSessionCookieName from "utils/auth/getSessionCookieName";

// The real session cookie is HTTP-only, and thus cannot be accessed via JS.
// This cookie is NOT HTTP-only, and can be used by the frontend to determine
// if the user is logged in.
//
// Inspired by https://stackoverflow.com/a/63877877.
export default function getSessionFlagCookieName(environment: Environment) {
  return getSessionCookieName(environment) + "-flag";
}
