import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { Suspense } from "react";
import { TestPageQuery } from "pages/test/__generated__/TestPageQuery.graphql";

const query = graphql`
  query TestPageQuery {
    test
  }
`;

function LoadQuery() {
  const data = useLazyLoadQuery<TestPageQuery>(query, {});
  return <div>{data.test}</div>;
}

export default function TestPage() {
  return (
    <Suspense fallback={<div>Fallback</div>}>
      <LoadQuery />
    </Suspense>
  );
}
