import {
  Environment,
  FetchFunction,
  Network,
  Observable,
  RecordSource,
  Store,
} from "relay-runtime";

import { SubscriptionClient } from "subscriptions-transport-ws";
import fetchGraphql from "utils/graphql/fetchGraphql";

/**
 * Relay requires developers to configure a "fetch" function that tells Relay how to load
 * the results of GraphQL queries from your server (or other data source). See more at
 * https://relay.dev/docs/en/quick-start-guide#relay-environment.
 */
const fetchRelay: FetchFunction = async (params, variables, _cacheConfig) => {
  const response = await fetchGraphql(params.name, params.text, variables);
  const json = await response.json();
  return json;
};

// Copied from https://relay.dev/docs/guided-tour/updating-data/graphql-subscriptions/#configuring-the-network-layer
const subscriptionClient = () =>
  new SubscriptionClient(process.env.REACT_APP_WS_GRAPHQL_URL as string, {
    reconnect: true,
  });

// Copied from https://relay.dev/docs/guided-tour/updating-data/graphql-subscriptions/#configuring-the-network-layer
// @ts-ignore
const subscribe = (request, variables) => {
  // @ts-ignore
  const subscribeObservable = subscriptionClient.request({
    operationName: request.name,
    query: request.text,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  // @ts-ignore
  return Observable.from(subscribeObservable);
};

const RelayEnvironment = new Environment({
  // @ts-ignore
  network: Network.create(fetchRelay, subscribe),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 20,
  }),
});

export default RelayEnvironment;
