import useGoogleIdentityScript from "hooks/scripts/useGoogleIdentityScript";
import { useEffect } from "react";
import ElementId from "types/enums/ElementId";
import GOOGLE_OAUTH_CLIENT_ID from "harken-shared/dist/constants/auth/GoogleOauthClientId";
import postLoginGoogle from "utils/rest/login/postLoginGoogle";
import notifyError from "components/toast/notifyError";
import firebaseLogin from "utils/firebase/auth/firebaseLogin";
import useViewerContext from "hooks/contexts/useViewerContext";

declare global {
  interface Window {
    google: any;
  }
}

// See https://developers.google.com/identity/gsi/web/guides/overview
// for more info about Google auth.
export default function GoogleLoginButton() {
  const status = useGoogleIdentityScript();
  const { setIsLoggingIn } = useViewerContext();

  useEffect(() => {
    if (status === "ready") {
      window.google.accounts.id.initialize({
        callback: async (response: any) => {
          const data = await postLoginGoogle({
            idToken: response.credential,
          });

          if (data == null) {
            notifyError();
            return;
          }

          if (data.firebaseToken != null) {
            try {
              setIsLoggingIn(true);
              await firebaseLogin(data.firebaseToken);
            } catch (e) {
              setIsLoggingIn(false);
              notifyError((e as Error).message);
              return;
            }
          }

          if (data.redirect != null) {
            window.location.href = data.redirect;
            return;
          }

          if (data.errorDescription != null) {
            notifyError(data.errorDescription, data.errorMessage);
          }
        },
        client_id: GOOGLE_OAUTH_CLIENT_ID,
      });

      const parent = document.getElementById(ElementId.GoogleLoginButton);
      window.google.accounts.id.renderButton(parent, {
        shape: "pill",
        size: "large",
        theme: "outline",
      });
    }
  }, [setIsLoggingIn, status]);

  return <div id={ElementId.GoogleLoginButton} />;
}
