import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";

type Props = {
  message?: string;
};

export default function Page404Content({
  message = "This is not the page you're looking for...",
}: Props) {
  return (
    <Header2 colorClass={ColorClass.Primary} textAlign="center">
      {message}
    </Header2>
  );
}
