import ButtonWithText from "components/buttons/ButtonWithText";
import TextInput from "components/inputs/text/TextInput";
import FlexBox from "components/layout/FlexBox";
import { useState } from "react";
import invariant from "tiny-invariant";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import firebaseLogin from "utils/firebase/auth/firebaseLogin";
import postLoginEmail, {
  ResponseBody as LoginEmailResponseBody,
} from "utils/rest/login/postLoginEmail";
import isValidUsername from "harken-shared/dist/utils/validation/isValidUsername";
import notifyError from "components/toast/notifyError";
import postLoginGoogle, {
  ResponseBody as LoginGoogleResponseBody,
} from "utils/rest/login/postLoginGoogle";
import { Maybe } from "harken-shared/dist/types/UtilityTypes";
import useViewerContext from "hooks/contexts/useViewerContext";

type Props = {
  createAccountNonce: Maybe<string>;
  googleIdToken: Maybe<string>;
};

export default function RegisterCreateAccount({
  createAccountNonce,
  googleIdToken,
}: Props) {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoggingIn } = useViewerContext();

  const loginCommonCallback = async (
    responseBody: Maybe<LoginEmailResponseBody | LoginGoogleResponseBody>
  ) => {
    if (responseBody == null) {
      setIsLoading(false);
      notifyError();
      return;
    }

    if (responseBody.firebaseToken != null) {
      try {
        setIsLoggingIn(true);
        await firebaseLogin(responseBody.firebaseToken);
      } catch (e) {
        setIsLoggingIn(false);
        notifyError((e as Error).message);
        setIsLoading(false);
        return;
      }
    }

    if (responseBody.redirect != null) {
      window.location.href = responseBody.redirect;
      return;
    }

    invariant(responseBody.errorDescription != null);
    setIsLoading(false);
    notifyError(responseBody.errorDescription, responseBody.errorMessage);
  };

  const loginEmailCallback = async (createAccountNonceInner: string) => {
    setIsLoading(true);
    const data = await postLoginEmail({
      createAccountNonce: createAccountNonceInner,
      username,
    });

    return loginCommonCallback(data);
  };

  const loginGoogleCallback = async (googleIdTokenInner: string) => {
    setIsLoading(true);
    const data = await postLoginGoogle({
      idToken: googleIdTokenInner,
      username,
    });

    return loginCommonCallback(data);
  };

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={24}>
      <TextInput
        label="Username"
        onChange={setUsername}
        placeholder="Enter your desired username"
        value={username}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Navy}
        disabled={!isValidUsername(username)}
        fontClass={FontClass.NavLink}
        isLoading={isLoading}
        onClick={async () => {
          invariant(createAccountNonce != null || googleIdToken != null);
          if (createAccountNonce != null) {
            await loginEmailCallback(createAccountNonce);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await loginGoogleCallback(googleIdToken!);
          }
        }}
      >
        Create Account
      </ButtonWithText>
    </FlexBox>
  );
}
