// Misc global CSS
import "css/Antd.css";
import "css/Global.css";

// Colors
import "css/colors/ColorVariables.css";
import "css/colors/ColorVariablesDarkMode.css";
import "css/colors/ColorVariablesLightMode.css";
import "css/colors/BackgroundColorClasses.css";
import "css/colors/ColorClasses.css";

// Fonts
import "css/fonts/TanNimbus.css";
import "css/fonts/FontClasses.css";
import "css/fonts/FontSizes.css";
import "css/fonts/FontVariables.css";

// Shadows
import "css/shadows/ShadowVariables.css";

// Z index
import "css/z-index/ZIndexVariables.css";

// Initialize Firebase
import "utils/firebase/initializeFirebase";

import { RelayEnvironmentProvider } from "react-relay";
import RelayEnvironment from "utils/graphql/RelayEnvironment";
import { BrowserRouter } from "react-router-dom";
import Routes from "Routes";
import GlobalErrorBoundary from "components/errors/GlobalErrorBoundary";
import { ViewerContextProvider } from "context/ViewerContext";

export default function App() {
  return (
    <GlobalErrorBoundary>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <ViewerContextProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ViewerContextProvider>
      </RelayEnvironmentProvider>
    </GlobalErrorBoundary>
  );
}
