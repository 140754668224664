/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import ColorVariableDarkModeName from "types/enums/ColorVariableDarkModeName";
import ColorVariableName from "types/enums/ColorVariableName";

const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableDarkModeName
> = {
  [ColorVariableName.ArtistPillShadow]:
    ColorVariableDarkModeName.ArtistPillShadow,
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableDarkModeName.BackgroundOverlay,
  [ColorVariableName.BrightPurple]: ColorVariableDarkModeName.BrightPurple,
  [ColorVariableName.CardBackground]: ColorVariableDarkModeName.CardBackground,
  [ColorVariableName.ClickableAreaBackground]:
    ColorVariableDarkModeName.ClickableAreaBackground,
  [ColorVariableName.DarkCerulean]: ColorVariableDarkModeName.DarkCerulean,
  [ColorVariableName.DarkPurple]: ColorVariableDarkModeName.DarkPurple,
  [ColorVariableName.Dimmer]: ColorVariableDarkModeName.Dimmer,
  [ColorVariableName.Error]: ColorVariableDarkModeName.Error,
  [ColorVariableName.FloatingElement]:
    ColorVariableDarkModeName.FloatingElement,
  [ColorVariableName.Footer]: ColorVariableDarkModeName.Footer,
  [ColorVariableName.Green]: ColorVariableDarkModeName.Green,
  [ColorVariableName.Ghost]: ColorVariableDarkModeName.Ghost,
  [ColorVariableName.LightBlue]: ColorVariableDarkModeName.LightBlue,
  [ColorVariableName.LightPink]: ColorVariableDarkModeName.LightPink,
  [ColorVariableName.LightPurple]: ColorVariableDarkModeName.LightPurple,
  [ColorVariableName.LightPurpleHover]:
    ColorVariableDarkModeName.LightPurpleHover,
  [ColorVariableName.LinkWater]: ColorVariableDarkModeName.LinkWater,
  [ColorVariableName.MenuPopup]: ColorVariableDarkModeName.MenuPopup,
  [ColorVariableName.Navy]: ColorVariableDarkModeName.Navy,
  [ColorVariableName.NavyHover]: ColorVariableDarkModeName.NavyHover,
  [ColorVariableName.OverlayButton]: ColorVariableDarkModeName.OverlayButton,
  [ColorVariableName.Pink]: ColorVariableDarkModeName.Pink,
  [ColorVariableName.Primary]: ColorVariableDarkModeName.Primary,
  [ColorVariableName.ProgressBar]: ColorVariableDarkModeName.ProgressBar,
  [ColorVariableName.Purple]: ColorVariableDarkModeName.Purple,
  [ColorVariableName.PurpleGradientHover]:
    ColorVariableDarkModeName.PurpleGradientHover,
  [ColorVariableName.Red]: ColorVariableDarkModeName.Red,
  [ColorVariableName.RedHover]: ColorVariableDarkModeName.RedHover,
  [ColorVariableName.Secondary]: ColorVariableDarkModeName.Secondary,
  [ColorVariableName.Shader]: ColorVariableDarkModeName.Shader,
  [ColorVariableName.SkeletonBase]: ColorVariableDarkModeName.SkeletonBase,
  [ColorVariableName.SkeletonHighlight]:
    ColorVariableDarkModeName.SkeletonHighlight,
  [ColorVariableName.SpotlightOverlay]:
    ColorVariableDarkModeName.SpotlightOverlay,
  [ColorVariableName.Tertiary]: ColorVariableDarkModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableDarkModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableDarkModeName.White,
  [ColorVariableName.Yellow]: ColorVariableDarkModeName.Yellow,
  [ColorVariableName.PurpleGradient]: ColorVariableDarkModeName.PurpleGradient,
};

export default COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME;
