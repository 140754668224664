import RedirectIfLoggedOutContainer from "components/auth/RedirectIfLoggedOutContainer";
import ButtonWithText from "components/buttons/ButtonWithText";
import PageBody from "components/containers/PageBody";
import PageWithHeaderAndFooter from "components/containers/PageWithHeaderAndFooter";
import FlexBox from "components/layout/FlexBox";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import logout from "utils/auth/logout";

export default function LogoutPage() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <RedirectIfLoggedOutContainer>
      <PageWithHeaderAndFooter>
        <PageBody header="Log Out">
          <FlexBox justifyContent="center">
            <ButtonWithText
              buttonTheme={ButtonTheme.Navy}
              fontClass={FontClass.NavLink}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true);
                logout();
                setIsLoading(false);
              }}
            >
              Log out
            </ButtonWithText>
          </FlexBox>
        </PageBody>
      </PageWithHeaderAndFooter>
    </RedirectIfLoggedOutContainer>
  );
}
