import PageWithBottomTabs from "components/bottom-tabs/PageWithBottomTabs";
import styles from "components/containers/css/PageWithHeaderAndFooter.module.css";
import Footer from "components/footers/Footer";
import Header from "components/headers/Header";
import useIsBottomTabsWidth from "hooks/dimensions/useIsBottomTabsWidth";

type Props = {
  children: any;
  hasBottomTabs?: boolean;
};

export default function PageWithHeaderAndFooter({
  children,
  hasBottomTabs = false,
}: Props): JSX.Element {
  const isBottomTabsWidth = useIsBottomTabsWidth();

  return (
    <PageWithBottomTabs hideBottomTabs={!hasBottomTabs}>
      <div className={styles.container}>
        <Header />
        <div className={styles.body}>{children}</div>
        {isBottomTabsWidth && hasBottomTabs ? null : <Footer />}
      </div>
    </PageWithBottomTabs>
  );
}
