import { useEffect, useState } from "react";
import getSessionFlagCookieName from "harken-shared/dist/utils/auth/getSessionFlagCookieName";
import getCookieByName from "utils/cookie/getCookieByName";
import getEnvironment from "utils/env/getEnvironment";

// Checking for the session cookie is the quickest way to see if someone is logged in
export default function useIsLoggedIn(): boolean {
  const [isLoggedIn, setIsLoggedIn] = useState(
    getCookieByName(getSessionFlagCookieName(getEnvironment())) === "1"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const isLoggedInInner =
        getCookieByName(getSessionFlagCookieName(getEnvironment())) === "1";
      if (isLoggedInInner !== isLoggedIn) {
        setIsLoggedIn(isLoggedInInner);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  return isLoggedIn;
}
