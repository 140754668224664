import { Popover } from "antd";
import HamburgerButton from "components/buttons/HamburgerButton";
import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import styles from "components/headers/css/Header.module.css";
import FlexBox from "components/layout/FlexBox";
import Header2 from "components/text/Header2";
import useIsLoggedIn from "hooks/auth/useIsLoggedIn";
import { useState } from "react";
import emptyFunction from "harken-shared/dist/utils/emptyFunction";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import logout from "utils/auth/logout";

function PopoverContent({
  setIsPopoverOpen,
}: {
  setIsPopoverOpen: (val: boolean) => void;
}) {
  const isLoggedIn = useIsLoggedIn();

  return (
    <FlexBox flexDirection="column" gap={24}>
      {isLoggedIn ? (
        <TextButton
          buttonThemeOrColorClass={ColorClass.Primary}
          fontClass={FontClass.NavLink}
          onClick={() => {
            setIsPopoverOpen(false);
            logout();
          }}
        >
          Log out
        </TextButton>
      ) : (
        <TextButton
          buttonThemeOrColorClass={ColorClass.Primary}
          fontClass={FontClass.NavLink}
          href="/login"
          onClick={() => {
            setIsPopoverOpen(false);
          }}
          type="link_internal"
        >
          Log in
        </TextButton>
      )}
    </FlexBox>
  );
}

export default function Header(): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className={styles.container}>
      <ResponsiveContainer className={styles.header}>
        <HamburgerButton
          colorValue={ColorValue.Secondary}
          isShown={false}
          onClick={emptyFunction}
        />
        <Header2 colorClass={ColorClass.Primary}>Side Project Stack</Header2>
        <Popover
          content={<PopoverContent setIsPopoverOpen={setIsPopoverOpen} />}
          open={isPopoverOpen}
          onOpenChange={setIsPopoverOpen}
          placement="bottomRight"
          trigger="click"
        >
          <HamburgerButton
            colorValue={ColorValue.Secondary}
            onClick={() => setIsPopoverOpen((val) => !val)}
          />
        </Popover>
      </ResponsiveContainer>
    </div>
  );
}
