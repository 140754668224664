/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

enum ColorVariableName {
  ArtistPillShadow = "--color-artistPillShadow",
  BackgroundOverlay = "--color-backgroundOverlay",
  BrightPurple = "--color-brightPurple",
  CardBackground = "--color-cardBackground",
  ClickableAreaBackground = "--color-clickableAreaBackground",
  DarkCerulean = "--color-darkCerulean",
  DarkPurple = "--color-darkPurple",
  Dimmer = "--color-dimmer",
  Error = "--color-error",
  FloatingElement = "--color-floatingElement",
  Footer = "--color-footer",
  Ghost = "--color-ghost",
  Green = "--color-green",
  LightBlue = "--color-lightBlue",
  LightPink = "--color-lightPink",
  LightPurple = "--color-lightPurple",
  LightPurpleHover = "--color-lightPurpleHover",
  LinkWater = "--color-linkWater",
  MenuPopup = "--color-menuPopup",
  Navy = "--color-navy",
  NavyHover = "--color-navyHover",
  OverlayButton = "--color-overlayButton",
  Pink = "--color-pink",
  Primary = "--color-primary",
  ProgressBar = "--color-progressBar",
  Purple = "--color-purple",
  PurpleGradient = "--color-purpleGradient",
  PurpleGradientHover = "--color-purpleGradientHover",
  Red = "--color-red",
  RedHover = "--color-redHover",
  Secondary = "--color-secondary",
  Shader = "--color-shader",
  SkeletonBase = "--color-skeletonBase",
  SkeletonHighlight = "--color-skeletonHighlight",
  SpotlightOverlay = "--color-spotlightOverlay",
  Tertiary = "--color-tertiary",
  WebsiteBackground = "--color-websiteBackground",
  White = "--color-white",
  Yellow = "--color-yellow",
}

export default ColorVariableName;
