import notify from "components/toast/notify";
import useIsLoggedIn from "hooks/auth/useIsLoggedIn";
import { useEffect } from "react";

type Props = {
  children: any;
};

export default function RedirectIfLoggedOutContainer({ children }: Props) {
  const isLoggedIn = useIsLoggedIn();

  // Note: because of React strict mode, this will fire twice on localhost
  // See https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  useEffect(() => {
    if (!isLoggedIn) {
      notify({
        description: "Redirecting to the login page...",
        message: "You are not logged in",
        type: "info",
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
  }, [isLoggedIn]);

  return children;
}
