/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEventHandler, forwardRef, CSSProperties } from "react";

import AnalyticsEvent from "types/enums/AnalyticsEvent";
import ButtonName from "types/enums/ButtonName";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import { Link } from "react-router-dom";
import joinClasses from "utils/joinClasses";
import styles from "components/buttons/css/ButtonWithText.module.css";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import logEvent from "utils/analytics/logEvent";
import assertUnreachable from "harken-shared/dist/utils/assertUnreachable";
import FlexBox from "components/layout/FlexBox";
import AnchorTarget from "types/AnchorTarget";
import ElementId from "types/enums/ElementId";
import enumValueToKey from "harken-shared/dist/utils/enum/enumValueToKey";

type Props = {
  buttonName?: ButtonName;
  buttonTheme: ButtonTheme;
  children: string | JSX.Element | Array<string | JSX.Element>;
  className?: string;
  disabled?: boolean;
  fontClass: FontClass;
  height?: number;
  href?: string;
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  id?: ElementId;
  isLoading?: boolean;
  logProperties?: { [key: string]: any };
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  target?: AnchorTarget;
  textTransform?: "none" | "uppercase";
  type?: "button" | "link_external" | "link_internal" | "submit";
  width?: "auto" | "100%";
};

const BUTTON_THEME_TO_SPINNER_COLOR = {
  [ButtonTheme.Navy]: ColorValue.White,
} as const;

function getClassNameForButtonTheme(
  buttonTheme: ButtonTheme,
  isLink: boolean,
  disabled: boolean
): string {
  switch (buttonTheme) {
    case ButtonTheme.Navy:
      return joinClasses(
        isLink ? styles.navyThemeLink : styles.navyTheme,
        disabled ? styles.disabled : null
      );
    default:
      return assertUnreachable(buttonTheme);
  }
}

// forwardRef required to make this work with NextJS Link, see
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component.
const ButtonWithText = forwardRef<HTMLButtonElement, Props>(
  (
    {
      buttonName,
      buttonTheme,
      children,
      className,
      disabled = false,
      fontClass,
      height,
      href,
      icon,
      iconPosition = "right",
      id,
      logProperties,
      onClick,
      isLoading = false,
      style = {},
      target = "_blank",
      textTransform,
      type = "button",
      width = "auto",
    }: Props,
    ref
  ) => {
    const styleToUse = {
      ...style,
      height,
      ...(textTransform == null ? {} : { textTransform }),
    };
    const classNameJoined = joinClasses(
      getClassNameForButtonTheme(
        buttonTheme,
        type === "link_internal" || type === "link_external",
        disabled
      ),
      styles.button,
      width === "auto" ? styles.buttonAutoWidth : undefined,
      fontClass,
      className
    );
    const childrenWithIcon = (
      <>
        {icon && iconPosition === "left" && (
          <div
            className={joinClasses(
              styles.icon,
              styles.iconLeft,
              GlobalClass.HideText
            )}
          >
            {icon}
          </div>
        )}
        <FlexBox flexDirection="column">
          {isLoading && (
            <LoadingSpinner
              colorValue={BUTTON_THEME_TO_SPINNER_COLOR[buttonTheme]}
              fontSize={enumValueToKey(FontClass, fontClass)}
            />
          )}
          <div
            // Do this to keep the width of the button the same even if LoadingSpinner is rendered
            className={joinClasses(isLoading ? styles.hideChildren : undefined)}
          >
            {children}
          </div>
        </FlexBox>
        {icon && iconPosition === "right" && (
          <div
            className={joinClasses(
              styles.icon,
              styles.iconRight,
              GlobalClass.HideText
            )}
          >
            {icon}
          </div>
        )}
      </>
    );
    const onClickWithLog: MouseEventHandler = (e) => {
      if (buttonName != null) {
        logEvent(AnalyticsEvent.ButtonClick, { buttonName, ...logProperties });
      }
      if (onClick != null) {
        onClick(e);
      }
    };

    if (type === "link_internal") {
      return (
        <Link className={styles.linkInternal} id={id} to={href ?? ""}>
          <div
            className={joinClasses(classNameJoined, styles.linkContent)}
            onClick={onClickWithLog}
            style={styleToUse}
          >
            {childrenWithIcon}
          </div>
        </Link>
      );
    }

    if (type === "link_external") {
      return (
        <a
          className={joinClasses(classNameJoined, styles.linkContent)}
          href={href ?? ""}
          id={id}
          onClick={onClickWithLog}
          style={styleToUse}
          target={target}
          rel={target === "_blank" ? "noreferrer" : undefined}
        >
          {childrenWithIcon}
        </a>
      );
    }

    return (
      <button
        ref={ref}
        className={classNameJoined}
        disabled={disabled || isLoading}
        id={id}
        onClick={onClickWithLog}
        style={styleToUse}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {childrenWithIcon}
      </button>
    );
  }
);

export default ButtonWithText;
