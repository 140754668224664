/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import ColorVariableLightModeName from "types/enums/ColorVariableLightModeName";
import ColorVariableName from "types/enums/ColorVariableName";

const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableLightModeName
> = {
  [ColorVariableName.ArtistPillShadow]:
    ColorVariableLightModeName.ArtistPillShadow,
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableLightModeName.BackgroundOverlay,
  [ColorVariableName.BrightPurple]: ColorVariableLightModeName.BrightPurple,
  [ColorVariableName.CardBackground]: ColorVariableLightModeName.CardBackground,
  [ColorVariableName.ClickableAreaBackground]:
    ColorVariableLightModeName.ClickableAreaBackground,
  [ColorVariableName.DarkCerulean]: ColorVariableLightModeName.DarkCerulean,
  [ColorVariableName.DarkPurple]: ColorVariableLightModeName.DarkPurple,
  [ColorVariableName.Dimmer]: ColorVariableLightModeName.Dimmer,
  [ColorVariableName.Error]: ColorVariableLightModeName.Error,
  [ColorVariableName.FloatingElement]:
    ColorVariableLightModeName.FloatingElement,
  [ColorVariableName.Footer]: ColorVariableLightModeName.Footer,
  [ColorVariableName.Green]: ColorVariableLightModeName.Green,
  [ColorVariableName.Ghost]: ColorVariableLightModeName.Ghost,
  [ColorVariableName.LightBlue]: ColorVariableLightModeName.LightBlue,
  [ColorVariableName.LightPink]: ColorVariableLightModeName.LightPink,
  [ColorVariableName.LightPurple]: ColorVariableLightModeName.LightPurple,
  [ColorVariableName.LightPurpleHover]:
    ColorVariableLightModeName.LightPurpleHover,
  [ColorVariableName.LinkWater]: ColorVariableLightModeName.LinkWater,
  [ColorVariableName.MenuPopup]: ColorVariableLightModeName.MenuPopup,
  [ColorVariableName.Navy]: ColorVariableLightModeName.Navy,
  [ColorVariableName.NavyHover]: ColorVariableLightModeName.NavyHover,
  [ColorVariableName.OverlayButton]: ColorVariableLightModeName.OverlayButton,
  [ColorVariableName.Pink]: ColorVariableLightModeName.Pink,
  [ColorVariableName.Primary]: ColorVariableLightModeName.Primary,
  [ColorVariableName.ProgressBar]: ColorVariableLightModeName.ProgressBar,
  [ColorVariableName.Purple]: ColorVariableLightModeName.Purple,
  [ColorVariableName.PurpleGradientHover]:
    ColorVariableLightModeName.PurpleGradientHover,
  [ColorVariableName.Red]: ColorVariableLightModeName.Red,
  [ColorVariableName.RedHover]: ColorVariableLightModeName.RedHover,
  [ColorVariableName.Secondary]: ColorVariableLightModeName.Secondary,
  [ColorVariableName.Shader]: ColorVariableLightModeName.Shader,
  [ColorVariableName.SkeletonBase]: ColorVariableLightModeName.SkeletonBase,
  [ColorVariableName.SkeletonHighlight]:
    ColorVariableLightModeName.SkeletonHighlight,
  [ColorVariableName.SpotlightOverlay]:
    ColorVariableLightModeName.SpotlightOverlay,
  [ColorVariableName.Tertiary]: ColorVariableLightModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableLightModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableLightModeName.White,
  [ColorVariableName.Yellow]: ColorVariableLightModeName.Yellow,
  [ColorVariableName.PurpleGradient]: ColorVariableLightModeName.PurpleGradient,
};

export default COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME;
