import styles from "components/footers/css/Footer.module.css";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";

export default function Footer(): JSX.Element {
  return (
    <div className={styles.footer}>
      <Body1 colorClass={ColorClass.Primary}>Footer</Body1>
    </div>
  );
}
