import Page404 from "pages/errors/Page404";
import LandingPage from "pages/landing/LandingPage";
import LoginOrRegisterPage from "pages/login/LoginOrRegisterPage";
import LogoutPage from "pages/logout/LogoutPage";
import TestPage from "pages/test/TestPage";
import { Route, Routes as RoutesImport } from "react-router-dom";

export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/login"
        element={<LoginOrRegisterPage loginOrRegister="login" />}
      />
      <Route path="/logout" element={<LogoutPage />} />
      <Route
        path="/register"
        element={<LoginOrRegisterPage loginOrRegister="register" />}
      />
      <Route path="/test" element={<TestPage />} />
      <Route path="*" element={<Page404 />} />
    </RoutesImport>
  );
}
