import {
  firebaseAnalytics,
  firebaseAuth,
} from "utils/firebase/initializeFirebase";
import { signInWithCustomToken } from "firebase/auth";
import { setUserId } from "firebase/analytics";

export default async function firebaseLogin(token: string): Promise<void> {
  const userCredential = await signInWithCustomToken(firebaseAuth, token);
  if (userCredential.user) {
    setUserId(firebaseAnalytics, userCredential.user.uid);
  }
}
