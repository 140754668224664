import ButtonWithText from "components/buttons/ButtonWithText";
import TextInput from "components/inputs/text/TextInput";
import FlexBox from "components/layout/FlexBox";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import LoginOrRegister from "types/LoginOrRegister";
import isValidEmail from "harken-shared/dist/utils/validation/isValidEmail";
import postLoginEmail from "utils/rest/login/postLoginEmail";
import TextButton from "components/buttons/TextButton";
import ColorClass from "types/enums/ColorClass";
import notifyError from "components/toast/notifyError";

type Props = {
  loginOrRegister: LoginOrRegister;
  onSuccess: () => void;
};

/**
 * A form that allows users to input their email.
 *
 * For passwordless login, i.e. email nonce login.
 *
 * Used for both login and registration.
 */
export default function LoginOrRegisterEmailForm({
  loginOrRegister,
  onSuccess,
}: Props) {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FlexBox alignItems="center" flexDirection="column" gap={24}>
      <TextInput
        label="Email Address"
        onChange={setEmail}
        placeholder="Enter email"
        value={email}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Navy}
        disabled={!isValidEmail(email)}
        fontClass={FontClass.NavLink}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          const data = await postLoginEmail({
            email,
          });

          if (data == null) {
            setIsLoading(false);
            notifyError();
            return;
          }

          if (data.redirect != null) {
            window.location.href = data.redirect;
            return;
          }

          setIsLoading(false);
          if (data.errorDescription != null) {
            notifyError(data.errorDescription, data.errorMessage);
            return;
          }

          onSuccess();
        }}
      >
        {loginOrRegister === "login" ? "Sign In" : "Create Account"}
      </ButtonWithText>
      <TextButton
        buttonThemeOrColorClass={ColorClass.Secondary}
        fontClass={FontClass.Body2}
        href={loginOrRegister === "login" ? "/register" : "/login"}
        type="link_internal"
      >
        {loginOrRegister === "login"
          ? "Don't have an account? Register here"
          : "Already have an account? Sign in here"}
      </TextButton>
    </FlexBox>
  );
}
