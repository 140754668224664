import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "App";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import Environment from "harken-shared/dist/types/enums/Environment";
import assertUnreachable from "harken-shared/dist/utils/assertUnreachable";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import getSentryEventSeverity from "utils/analytics/getSentryEventSeverity";
import getEnvironment from "utils/env/getEnvironment";
import PROJECT_NAME from "harken-shared/dist/constants/ProjectName";

const EVENT_SOURCE = "frontend";

function getTracesSampleRate() {
  const env = getEnvironment();
  switch (env) {
    case Environment.Local:
      return 0;
    case Environment.Development:
      return 0.01;
    case Environment.Production:
      return 0.05;
    default:
      return assertUnreachable(env);
  }
}

Sentry.init({
  attachStacktrace: true,
  beforeBreadcrumb(breadcrumb, hint) {
    if (
      breadcrumb.level === "error" &&
      ["fetch", "xhr"].includes(breadcrumb.category || "")
    ) {
      return {
        ...breadcrumb,
        data: {
          ...(breadcrumb.data || {}),
          request: hint?.input || hint?.xhr?.__sentry_xhr__,
        },
      };
    }
    return breadcrumb;
  },
  beforeSend(event) {
    // eslint-disable-next-line no-param-reassign
    event.tags = { event: AnalyticsEvent.TopLevelEvent, ...(event.tags ?? {}) };

    const severity = getSentryEventSeverity(event);
    if (severity != null) {
      // eslint-disable-next-line no-param-reassign
      event.level = severity;
    }

    return event;
  },
  dsn: "https://e468bc4ef14f4ab4a5e17d9b8d766c32@o4504347899592704.ingest.sentry.io/4504352427671552",
  environment: getEnvironment(),
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 6,

  release: process.env.REACT_APP_RELEASE,
  tracesSampleRate: getTracesSampleRate(),
});
Sentry.setTag("eventSource", EVENT_SOURCE);
Sentry.setTag("projectName", PROJECT_NAME);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
