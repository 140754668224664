import axios from "axios";
import { Maybe } from "harken-shared/dist/types/UtilityTypes";
import consoleLogErrorIfNotProd from "utils/analytics/consoleLogErrorIfNotProd";
import getRestUrl from "utils/env/getRestUrl";

type RequestBody = {
  idToken: string;
  username?: string;
};

export type ResponseBody = {
  errorDescription?: string;
  errorMessage?: string;
  firebaseToken?: string;
  redirect?: string;
  success: boolean;
};

export default async function postLoginGoogle(
  requestBody: RequestBody
): Promise<Maybe<ResponseBody>> {
  try {
    const { data } = await axios.post<ResponseBody>(
      getRestUrl("login/google"),
      requestBody,
      {
        // Needed so session cookie gets set
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    consoleLogErrorIfNotProd(e as Error);
    return null;
  }
}
