import { CSSProperties } from "react";
import ColorValue from "types/enums/ColorValue";
import joinClasses from "utils/joinClasses";
import styles from "components/loading/css/LoadingSpinner.module.css";
import FontSize from "types/enums/FontSize";
import { MaybeUndef } from "harken-shared/dist/types/UtilityTypes";

const COLOR_MAP = {
  [ColorValue.Primary]: styles.primary,
  [ColorValue.Secondary]: styles.secondary,
  [ColorValue.White]: styles.white,
};

type Props = {
  className?: string;
  colorValue: keyof typeof COLOR_MAP;
  // For when you want the loading spinner to have the same height and width
  // as a font's line height.
  fontSize?: MaybeUndef<keyof typeof FontSize>;
  size?: number;
  style?: CSSProperties;
};

export default function LoadingSpinner({
  className,
  colorValue,
  fontSize,
  size = 40,
  style = {},
}: Props): JSX.Element {
  const colorClassName = COLOR_MAP[colorValue];
  const styleInner = fontSize != null ? {} : { height: size, width: size };

  return (
    <div
      className={joinClasses(
        styles.ldsDualRing,
        colorClassName,
        className,
        fontSize
      )}
      style={{ ...style, ...styleInner }}
    />
  );
}
