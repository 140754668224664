import axios from "axios";
import AnalyticsEvent from "types/enums/AnalyticsEvent";
import getDefaultLogProperties from "utils/analytics/getDefaultLogProperties";
import toObject from "harken-shared/dist/utils/object/toObject";
import getRestUrl from "utils/env/getRestUrl";
import emptyFunction from "harken-shared/dist/utils/emptyFunction";

export default function logEvent(
  event: AnalyticsEvent,
  data?: Record<string, any>
) {
  const properties = {
    ...(data == null ? {} : toObject(data)),
    ...getDefaultLogProperties(),
  };

  axios
    .post(getRestUrl("logEventToLoki"), {
      event,
      properties,
    })
    // Swallow errors
    .catch(emptyFunction);
}
