import notify from "components/toast/notify";
import NotifyErrorDescription from "types/enums/NotifyErrorDescription";
import NotifyErrorMessage from "types/enums/NotifyErrorMessage";

export default function notifyError(
  description?: NotifyErrorDescription | string,
  message?: NotifyErrorMessage | string
) {
  notify({
    description: description ?? NotifyErrorDescription.UnexpectedError,
    message: message ?? NotifyErrorMessage.UnexpectedError,
    type: "error",
  });
}
