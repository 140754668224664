import notifyError from "components/toast/notifyError";
import NotifyErrorDescription from "types/enums/NotifyErrorDescription";
import firebaseLogout from "utils/firebase/auth/firebaseLogout";
import postLogout from "utils/rest/login/postLogout";

export default async function logout() {
  const result = await postLogout();
  if (result == null || !result.success) {
    notifyError(NotifyErrorDescription.UnexpectedLogoutError);
    return;
  }

  await firebaseLogout();

  window.location.href = "/login";
}
