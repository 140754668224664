import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Maybe } from "harken-shared/dist/types/UtilityTypes";
import invariant from "tiny-invariant";
import Dimensions from "types/Dimensions";
import getAssetDimensions from "utils/assets/getAssetDimensions";

/**
 * Uploads a file to Firebase storage.
 */
export default async function uploadFile(
  file: File,
  path: string,
  firebaseUserId?: string
): Promise<{
  dimensions: Maybe<Dimensions>;
  downloadUrl: string;
  fullPath: string;
  type: string;
}> {
  invariant(path[0] !== "/", "Path must not start with a slash");
  const storageRef = ref(
    getStorage(),
    firebaseUserId == null ? path : `users/${firebaseUserId}/${path}`
  );
  const uploadResult = await uploadBytes(storageRef, file);
  const fileDataUrl = URL.createObjectURL(file);
  const dimensions = await getAssetDimensions(file, fileDataUrl);

  return {
    dimensions,
    downloadUrl: await getDownloadURL(uploadResult.ref),
    fullPath: uploadResult.metadata.fullPath,
    type: file.type,
  };
}
