import { useEffect } from "react";

export default function useDisableBodyScroll(shouldDisable = true) {
  // Copied from https://stackoverflow.com/questions/9538868/prevent-body-from-scrolling-when-a-modal-is-opened/69005672#69005672.
  useEffect(() => {
    if (shouldDisable) {
      const width = document.body.clientWidth;
      document.body.style.overflowY = "hidden";
      document.body.style.width = `${width}px`;
    } else {
      document.body.style.overflowY = "scroll";
      document.body.style.width = `100%`;
    }

    return () => {
      document.body.style.overflowY = "scroll";
      document.body.style.width = `100%`;
    };
  }, [shouldDisable]);
}
